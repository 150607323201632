import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './App.css';

function App() {
  const [uploadedImages, setUploadedImages] = useState(() => JSON.parse(localStorage.getItem('uploadedImages')) || []);
  const [selectedImage, setSelectedImage] = useState(null);
  const [sketchSrc, setSketchSrc] = useState(null);
  const [threshold, setThreshold] = useState(128);
  const [outlineColor, setOutlineColor] = useState("#000000");
  const [savedSketches, setSavedSketches] = useState(() => JSON.parse(localStorage.getItem('savedSketches')) || []);
  const canvasRef = useRef(null);

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach((file, index) => {
      const reader = new FileReader();
      reader.onload = () => {
        const newImage = { id: uploadedImages.length + index, src: reader.result };
        setUploadedImages(prevImages => {
          const updatedImages = [...prevImages, newImage];
          localStorage.setItem('uploadedImages', JSON.stringify(updatedImages));
          return updatedImages;
        });
      };
      reader.readAsDataURL(file);
    });
  }, [uploadedImages]);

  const generateSketch = () => {
    if (!selectedImage) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = selectedImage.src;
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      const outlineR = parseInt(outlineColor.substring(1, 3), 16);
      const outlineG = parseInt(outlineColor.substring(3, 5), 16);
      const outlineB = parseInt(outlineColor.substring(5, 7), 16);
      
      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        
        const gray = 0.299 * r + 0.587 * g + 0.114 * b;
        const thresholdValue = gray > threshold ? 255 : 0;
        
        data[i] = data[i + 1] = data[i + 2] = thresholdValue;

        if (thresholdValue === 0) {
          data[i] = outlineR;
          data[i + 1] = outlineG;
          data[i + 2] = outlineB;
        }
      }
      
      ctx.putImageData(imageData, 0, 0);
      removeBackground(canvas.toDataURL('image/png')).then(modifiedImageSrc => {
        setSketchSrc(modifiedImageSrc);
      });
    };
  };

  const removeBackground = imageSrc => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.crossOrigin = "Anonymous"; // Zezwala na ładowanie obrazków z innych domen
      image.src = imageSrc;
      
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);
  
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
  
        for (let i = 0; i < data.length; i += 4) {
          const red = data[i];
          const green = data[i + 1];
          const blue = data[i + 2];
  
          // Sprawdza, czy piksel jest biały (równy 255, 255, 255)
          if (red === 255 && green === 255 && blue === 255) {
            // Ustawia przezroczystość alpha na 0
            data[i + 3] = 0;
          }
        }
  
        ctx.putImageData(imageData, 0, 0);
  
        const modifiedImageSrc = canvas.toDataURL('image/png');
        resolve(modifiedImageSrc); // Rozwiązuje obietnicę z modyfikowanym obrazkiem
      };
  
      image.onerror = error => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    generateSketch();
  }, [threshold, outlineColor, selectedImage]);

  const handleImageClick = image => {
    setSelectedImage(image);
  };

  const handleImageRemove = id => {
    setUploadedImages(prevImages => {
      const updatedImages = prevImages.filter(image => image.id !== id);
      localStorage.setItem('uploadedImages', JSON.stringify(updatedImages));
      return updatedImages;
    });
    if (selectedImage && selectedImage.id === id) {
      setSelectedImage(null);
      setSketchSrc(null);
    }
  };

  const handleSaveSketch = () => {
    if (sketchSrc) {
      setSavedSketches(prevSketches => {
        const newSketch = { id: prevSketches.length, src: sketchSrc };
        const updatedSketches = [...prevSketches, newSketch];
        localStorage.setItem('savedSketches', JSON.stringify(updatedSketches));
        return updatedSketches;
      });
    }
  };

  const handleRemoveAllImages = () => {
    setUploadedImages([]);
    localStorage.removeItem('uploadedImages');
    setSelectedImage(null);
    setSketchSrc(null);
  };

  const handleRemoveAllSketches = () => {
    setSavedSketches([]);
    localStorage.removeItem('savedSketches');
  };

  return (
    <div className="App">
      <h1>Prześlij zdjęcie, aby utworzyć kolorowankę</h1>
      <div className="container">
        <div className="left-column">
          <Dropzone onDrop={onDrop} />
          <button onClick={handleRemoveAllImages} className="clear-button">Usuń wszystkie obrazy</button>
          <div className="image-list">
            {uploadedImages.map(image => (
              <div key={image.id} className="image-item">
                <img 
                  src={image.src} 
                  alt={`uploaded-${image.id}`} 
                  onClick={handleImageClick.bind(null, image)} 
                />
                <button onClick={handleImageRemove.bind(null, image.id)} className="remove-button">Usuń</button>
              </div>
            ))}
          </div>
        </div>
        <div className="center-column">
          {selectedImage && (
            <div className="editor">
              <img src={selectedImage.src} alt="selected" style={{ maxWidth: '100%', maxHeight: '300px' }} />
              <div className="controls">
                <label>
                  Próg:
                  <input 
                    type="range" 
                    min="0" 
                    max="255" 
                    value={threshold} 
                    onChange={e => setThreshold(e.target.value)} 
                    className="slider"
                  />
                  {threshold}
                </label>
                <label>
                  Kolor konturu:
                  <input 
                    type="color" 
                    value={outlineColor} 
                    onChange={e => setOutlineColor(e.target.value)} 
                    className="color-picker"
                  />
                </label>
              </div>
              {sketchSrc && (
                <div>
                  <img src={sketchSrc} alt="sketch" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                  <button onClick={handleSaveSketch} className="save-button">Zapisz kolorowankę</button>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="right-column">
          <button onClick={handleRemoveAllSketches} className="clear-button">Usuń wszystkie kolorowanki</button>
          <h2>Zapisane kolorowanki</h2>
          <div className="saved-sketches">
            {savedSketches.map(sketch => (
              <div key={sketch.id} className="sketch-item">
                <img src={sketch.src} alt={`sketch-${sketch.id}`} />
                <a href={sketch.src} download={`sketch-${sketch.id}.png`}>
                  <button className="download-button">Pobierz</button>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
}

function Dropzone({ onDrop }) {
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      <p>Przeciągnij tutaj plik lub kliknij, aby wybrać</p>
    </div>
  );
}

export default App;
